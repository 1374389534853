<template>
	<standard-container title="persone" description="" menuicon="persone">
		<v-btn v-if="currentUser.IsAdmin" fab color="warning" top right absolute :title="gTranslate('Aggiungi persona', {key: 'btn-add'})" @click="addItem">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<div class="d-block d-md-flex align-baseline">
					<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" :label="gTranslate('Ricerca libera')" hide-details clear-icon="mdi-close-circle" clearable />
					<v-autocomplete v-if="competenzeVisibili" v-model="filterCompetenza" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Filtra per competenza')" :items="competenzeVisibili" prepend-inner-icon="mdi-tools" class="ml-0 ml-md-10" hide-details clear-icon="mdi-close-circle" clearable item-text="NomeCompetenza" item-value="CodiceCompetenza" />
					<v-alert type="error" outlined text v-else class="ml-6">lista competenze non inizializzata</v-alert>
					<v-switch v-model="visualizzaNascosti" inset :label="gTranslate('Visualizza eliminati')" color="warning" class="ml-0 ml-md-10" />
				</div>
			</v-col>
			<v-col cols="12" class="mt-0 pt-0">
				<v-tabs color="warning" class="mt-0" right :value="modalitaVisualizzazione">
					<v-tab key="schede" href="#schede" :title="gTranslate('Visualizzazione a schede')">
						<v-icon>mdi-view-grid</v-icon>
					</v-tab>
					<v-tab key="griglia" href="#griglia" :title="gTranslate('Visualizzazione tabellare')">
						<v-icon>mdi-view-list</v-icon>
					</v-tab>
					<v-tab-item key="griglia" value="griglia">
						<v-row no-gutters>
							<v-col cols="12">
								<!-- Visualizzazione tabellare secondaria per SM -->
								<div class="d-block d-sm-none">
									<v-data-iterator
										:items="personePrefiltered"
										:items-per-page="itemsPerPage"
										:page.sync="page"
										:search="search"
										hide-default-footer
										@page-count="pageCount = $event"
									>
										<template v-slot:default="props">
											<v-list two-line>
												<template v-for="(item, index) in props.items">
													<v-list-item :key="item.Id+'smlist'" @click="editItem(item)">
														<v-list-item-avatar>
															<v-avatar :size="35">
																<img v-if="item.Immagine" alt="Avatar" :src="imagesPathPerson + item.Immagine + '?width=70&height=70&mode=crop&anchor=top'">
																<avatar v-else :username="item.FullName" :size="35" />
															</v-avatar>
														</v-list-item-avatar>
														<v-list-item-content>
															<v-list-item-title>{{ item.FullName }}</v-list-item-title>
															<v-list-item-subtitle>{{ item.Email }}</v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>
													<v-divider :key="index+'sm'" />
												</template>
											</v-list>
										</template>
									</v-data-iterator>
								</div>
								<!-- Visualizzazione tabellare primaria (nascosta a SM) -->
								<template class="d-none d-sm-block">
									<v-data-table 
										:items="personePrefiltered" 
										:headers="gTranslateTableHeader(tableHeaders)" 
										:search="search" 
										:sort-by="['FullName']" 
										:sort-desc="[false]"
										:page.sync="page"
										:items-per-page="itemsPerPage"
										item-key="Id"
										hide-default-footer
										@click:row="editItem"
										@page-count="pageCount = $event"
									>
										<template v-slot:item.Immagine="{ item }">
											<v-avatar :size="35">
												<img v-if="item.Immagine" alt="Avatar" :src="imagesPathPerson + item.Immagine + '?width=70&height=70&mode=crop&anchor=top'">
												<avatar v-else :username="item.FullName" :size="35" />
											</v-avatar>
										</template>
										<template v-slot:item.Visibile="{ item }">
											<v-icon v-if="!item.Visibile" :title="gTranslate('Non visibile')" color="warning">
												mdi-eye-off
											</v-icon>
										</template>
										<template v-slot:item.Delete="{ item }">
											<v-btn v-if="item.Visibile" text icon x-small color="warning" :title="gTranslate('Elimina persona')" @click.stop="deleteItem(item)">
												<v-icon>mdi-delete</v-icon>
											</v-btn>
											<v-btn v-else text icon x-small color="success" :title="gTranslate('Recupera persona eliminata')" @click.stop="undeleteItem(item)">
												<v-icon>mdi-delete-restore</v-icon>
											</v-btn>
											<code v-if="viewDebugElements">{{ item.Id }}</code>
										</template>
										<template v-slot:item.FullName="{ item }">
											<standard-label cssclass="subtitle-2">
												{{ item.FullName }}
											</standard-label>
										</template>
										<template v-slot:item.Email="{ item }">
											<v-btn v-if="item.Email" text x-small :href="'mailto:'+item.Email" style="margin-left:0;padding-left:0" @click.stop="nop">
												{{ item.Email }}
											</v-btn>
										</template>
										<template v-slot:item.CellulareAziendale="{ item }">
											<div class="d-flex flex-column justify-start">
												<v-btn :disabled="!item.CellulareAziendale" text x-small :target="cellularOpenMode ? '_blank' : ''" :href="(cellularOpenMode ? 'https://api.whatsapp.com/send?phone=' : 'tel:') + item.CellulareAziendale" style="margin-left:0;padding-left:0" @click.stop="nop">
													<v-icon v-if="item.CellulareAziendale" small color="grey" left>
														mdi-factory
													</v-icon> {{ item.CellulareAziendale }}
												</v-btn>
												<v-btn :disabled="!item.Cellulare" text x-small :target="cellularOpenMode ? '_blank' : ''" :href="(cellularOpenMode ? 'https://api.whatsapp.com/send?phone=' : 'tel:') + item.Cellulare" style="margin-left:0;padding-left:0" @click.stop="nop">
													<v-icon v-if="item.Cellulare" small color="grey" left>
														mdi-home
													</v-icon> {{ item.Cellulare }}
												</v-btn>
											</div>
										</template>
										<template v-slot:item.SediOperativeList="{ item }">
											<standard-label v-for="sede in item.SediOperativeList" :key="sede">
												{{ decodificaSedeOperative(sede) }}
											</standard-label>
										</template>
										<template v-slot:item.Competenza="{ item }">
											<template v-for="valutazione in item.ValutazioniList">
												<div v-if="valutazione.CodiceCompetenza === filterCompetenza" :key="valutazione.Id" class="d-flex justify-space-between">
													<standard-label>{{ gShortDate(valutazione.DataValutazione) }}</standard-label>
													<v-chip x-small :color="decodificaColoreLivelloCompetenza(valutazione.CodiceValutazione)" class="text-uppercase white--text">
														{{ decodificaEtichettaLivelloCompetenza(valutazione.CodiceValutazione) }}
													</v-chip>
												</div>
											</template>
										</template>
										<v-data-footer :items-per-page-options="itemsPerPageOptions" />
									</v-data-table>
								</template>
							</v-col>								
						</v-row>
					</v-tab-item>
					<v-tab-item key="schede" value="schede">
						<v-row no-gutters>
							<v-col cols="12">
								<!-- Visualizzazione a schede -->
								<v-data-iterator
									:items="personePrefiltered"
									:items-per-page="itemsPerPage"
									:page.sync="page"
									:search="search"
									hide-default-footer
									@page-count="pageCount = $event"
								>
									<template v-slot:default="props">
										<v-row>
											<v-col v-for="item in props.items" :key="item.Id" cols="12" sm="6" md="4" lg="3">
												<v-card elevation="6" @click="editItem(item)">
													<div class="d-flex align-center justify-middle" style="width:100%">
														<v-avatar tile size="100%" color="transparent">
															<img v-if="item.Immagine" alt="Avatar" :src="imagesPathPerson + item.Immagine + '?width=400&height=400&mode=crop&anchor=top'">
															<avatar v-else :username="item.FullName" :size="200" class="mt-5" />
														</v-avatar>
													</div>
													<v-card-title><h4>{{ item.FullName }}</h4></v-card-title>
													<v-card-subtitle>{{ item.Email }}</v-card-subtitle>
													<v-card-text>
														<template v-for="valutazione in item.ValutazioniList">
															<div v-if="valutazione.CodiceCompetenza === filterCompetenza" :key="valutazione.Id+'card'" class="d-flex justify-space-between">
																<standard-label>{{ gShortDate(valutazione.DataValutazione) }}</standard-label>
																<v-chip x-small :color="decodificaColoreLivelloCompetenza(valutazione.CodiceValutazione)" class="text-uppercase white--text">
																	{{ decodificaEtichettaLivelloCompetenza(valutazione.CodiceValutazione) }}
																</v-chip>
															</div>
														</template>
													</v-card-text>
												</v-card>
											</v-col>
										</v-row>
									</template>
								</v-data-iterator>							
							</v-col>
						</v-row>
					</v-tab-item>
				</v-tabs>
			</v-col>
		</v-row>

		<template v-slot:footer>
			<v-row class="mt-1">
				<v-col cols="6" sm="2" order="2" order-sm="1">
					<v-btn small class="mt-3" icon :title="gTranslate('Ricarica la lista')" @click="refresh">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="12" sm="8" order="1" order-sm="2">
					<v-pagination v-model="page" circle color="warning" :length="pageCount" :disabled="itemsPerPage === -1" />
				</v-col>
				<v-col cols="6" sm="2" order="3" order-sm="3">
					<div class="d-flex justify-end">
						<div style="max-width:100px">
							<v-select v-model="itemsPerPage" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :items="itemsPerPageOptions" :label="gTranslate('Righe')" />
						</div>
					</div>
				</v-col>
			</v-row>
		</template>

		<loading :loading="loading" />
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import StandardLabel from '@/components/standard-label'
import Loading from '@/components/loading'
import Avatar from 'vue-avatar'
export default {
	name: 'PersonePage',
	components: { StandardContainer, StandardLabel, Avatar, Loading },
	data: () => {
		return {
			modalitaVisualizzazione: 'griglia',
			page: 1,
			pageCount: 0,
			itemsPerPage: 20,
			loading: false,
			search: '',
			filterCompetenza: '',
			visualizzaNascosti: false,
			itemsPerPageOptions: [{text:'5', value:5},{text:'10', value:10},{text:'20', value:20},{text:'50', value:50},{text:'100', value:100},{text:'Tutti', value:-1}],	
			
			dialog:false,
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		imagesPathPerson() { return this.$store.getters['imagesPathPerson'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		currentUser() { return this.$store.getters['currentUser'] },
		persone() { return this.$store.getters['persone'] },
		competenze() { return this.$store.getters['competenze'] },
		competenzeVisibili() { return this.competenze ? this.competenze.filter(x => { return x.Visibile }) : null },
		// definizione colonne della tabella via computed, innanzitutto per visualizzare o meno la colonna competenza
		tableHeaders() { 
			// determina la view corrente (tra normla e competenza) a seconda che sia attiva o meno la ricerca per competenza
			var currentView = this.filteredByCompetenza ? 'competenza' : 'normal'
			var cols = [
				{ text: '', value: 'Immagine', views:['normal', 'competenza'], responsive:['xs', 'sm', 'md', 'lg', 'xl'] },
				{ text: 'Risorsa', value: 'FullName', views:['normal', 'competenza'], responsive:['xs', 'sm', 'md', 'lg', 'xl'] },
				{ text: 'Email', value: 'Email', views:['normal', 'competenza'], responsive:['md', 'lg', 'xl'] },
				{ text: 'Cellulare', value: 'CellulareAziendale', views:['normal', 'competenza'], responsive:['xs', 'sm', 'md', 'lg', 'xl'] },
				{ text: 'Sede operativa', value: 'SediOperativeList', views:['normal', 'competenza'], responsive:['md', 'lg', 'xl'] },
				{ text: '', value: 'Visibile', views:['normal', 'competenza'], responsive:['sm', 'md', 'lg', 'xl'] },
				{ text: 'Competenza', value: 'Competenza', views:['competenza'], responsive:['xs', 'sm', 'md', 'lg', 'xl'] },
				{ text: '', value: 'Delete', views:['normal'], responsive:['lg', 'xl'] },
			]
			// filtra le colonne che corrispondono alla view richiesta e alla dimensione schermo richiesta
			var resultCols = cols.filter(item => {return item.views.includes(currentView) && item.responsive.includes(this.$vuetify.breakpoint.name)})
			return resultCols
		},
		// true se è attivo il filtro per competenza, ovvero se filterCompetenza è valorizzato
		filteredByCompetenza() { return !!this.filterCompetenza },
		personePrefiltered() {
			return this.persone.filter((item) => {
				var filtroVisibilita = this.visualizzaNascosti || item.Visibile
				var filtroCompetenze = true
				if(this.filteredByCompetenza) {
					// se attivo il filtraggio per competenza allora controlla se nell'array delle valutazioni (ValutazioniList) esiste almeno un record con il CodiceCompetenza richiesto
					filtroCompetenze = item.ValutazioniList.some(itemValutazione => { return itemValutazione.CodiceCompetenza === this.filterCompetenza })
				}
				return filtroVisibilita && filtroCompetenze
			})
		},
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
	},
	watch:{
		visualizzaNascosti(to, from) {
			// quando canbia la visualizzazione dei nascosti ricarichiamo la lista persone, perché per default non vengono caricati
			if(to!==from) {
				this.refresh()
			}
		}
	},	
	mounted() {
		this.$refs.searchField.focus()
	},
	methods: {
		decodificaSedeOperative(codice) {
			if(!this.decodifiche || !this.decodifiche.Sedi) return '-'
			var dummy = this.decodifiche.Sedi.find(item => {return item.CodiceSede === codice})
			return dummy ? dummy.DescrizioneSede : '-'
		},
		decodificaEtichettaLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Livello'+livello]
			} 
			return null
		},
		decodificaColoreLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Colore'+livello]
			} 
			return null
		},		
		editItem(item) {
			this.$store.dispatch('checkUpdatedPersona', item).then((isUpdated) => {
				if(isUpdated){
					// la persona richiesta e' stata modificata da un altro utente, e' necessario prima ricaricare la lista
					this.loading = true
					this.$store.dispatch('refreshPersone', this.visualizzaNascosti).then(()=>{
						this.loading = false
						let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
						let swOk =  this.gTranslate('Si', {section: 'swal'})
						let swText =  this.gTranslate('Si è reso necessario ricaricare la lista delle persone poiché sono state rilevate modifiche da parte di un altro utente.')
						this.$swal(swTitle, swText, 'info', {
							buttons: {
								ok: swOk
							}
						})
					})
				} else {
					this.$store.dispatch('editPersona', item)
				}
			})
		},
		addItem() {
			this.loading = true
			this.$store.dispatch('addPersona').then(()=>{
				this.loading = false
			})
		},
		/*
		updateItem() {
			// aggiunge prima i dati della maschera ridotta e poi, una volta creato il record di base, porta alla pagina di editing standard
			this.$store.dispatch('addPersona', this.currentItem).then((persona) => {
				this.$store.dispatch('editPersona', persona)
			})
		},
		*/
		deleteItem(item) {
			let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
			let swYes =  this.gTranslate('Si', {section: 'swal'})
			let swNo =  this.gTranslate('No', {section: 'swal'})
			let swText =  this.gTranslate('Sei sicuro di voler cancellare')
			this.$swal(swTitle, swText + ' '+item.FullName+' ?', 'warning', {
				buttons: {
					ok: swYes,
					cancel: swNo
				}
			}).then(result => {
				if (result === 'ok') {
					this.loading = true
					this.$store.dispatch('deletePersona', item).then(()=>{
						this.loading = false
					})
				}
			}).catch((error) => {
				console.error(error)
			})
		},
		undeleteItem(item) {
			this.loading = true
			this.$store.dispatch('undeletePersona', item).then(()=>{
				this.loading = false
			})
		},
		// forza il refresh dei dati
		refresh() {
			this.loading = true
			this.$store.dispatch('refreshPersone', this.visualizzaNascosti).then(()=>{
				this.loading = false
			})
		},
		nop(){

		}
	}
}
</script>
